import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, Link } from "react-router-dom";

export const Splash = () => {
    const { isAuthenticated } = useAuth0();
    const history = useNavigate();

    if (isAuthenticated) {
        history("/Home"); //auto-redirect to home page.
        return null; 
    } else {
        return (
            <div>
                Shopping List... Click here to start!
                <Link to="/home">
                    <button>Log in</button>
                </Link>
            </div>
        );
    }
}
