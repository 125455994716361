import { ItemsContext } from "../context/ItemsContext";
import { useContext } from 'react';

export const useItemsContext = () => {
    const context = useContext(ItemsContext);

    if(!context) { 
        throw new Error('useItemsContext must be used within a ItemsContextProvider');
    }

    return context;
}
