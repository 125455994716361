import { Link } from 'react-router-dom';
import LoginButton from './LoginButton';
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from './LogoutButton';

const Navbar = () => {

    const { user, isAuthenticated } = useAuth0();

    return (
        <header>
            <div className="container">
                <Link to="/">
                    <h1>Shopping List</h1>
                </Link>
            </div>
            <div>
                { !isAuthenticated && <LoginButton/> }
                { isAuthenticated && <p>Welcome, {user.name} <LogoutButton/></p>  }    
            </div>
        </header>
    )
}


export default Navbar;