import { Routes, Route } from 'react-router-dom'
import { AuthenticationGuard } from './components/authentication-guard';
import { Callback } from './pages/Callback';
import { Splash } from './pages/Splash';
import { Profile } from './components/Profile';


// pages
import Home from './pages/Home'
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">

      
        <div className='pages'>
          <Routes>
            <Route path="/" element={<Splash/>} />
            <Route path='/Home' element={<AuthenticationGuard component={Home} />} />
            <Route path='/profile' element={<AuthenticationGuard component={Profile} />} />
            <Route path="/callback" element={<Callback/>} />
          </Routes>
        </div>
        
     
      
    </div>
  );
}

export default App;
