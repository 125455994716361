import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ItemsContextProvider } from './context/ItemsContext';
import { Auth0ProviderWithNavigate } from './components/auth0-provider-with-navigate';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
        <ItemsContextProvider>
          <App />
          </ItemsContextProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    
  </React.StrictMode>
);


