import { useEffect } from "react"
import { useItemsContext } from "../hooks/useItemsContext"
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

// components
import ItemDetails from "../components/ItemDetails"
import ItemForm from "../components/ItemForm"
import Navbar from "../components/Navbar";


const Home = () => {
  const { items, dispatch } = useItemsContext();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    
    const fetchItems = async () => {
      const accessToken = await getAccessTokenSilently();
      const apiBaseUrl = process.env.REACT_APP_API_SERVER_URL;
      console.log(`api Base Url: ${apiBaseUrl}`);
      const response = await fetch(`${apiBaseUrl}/api/items`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const json = await response.json();

      if (response.ok) {
        dispatch({type: 'SET_ITEMS', payload: json})
      }
    }

    fetchItems()
  }, [dispatch, getAccessTokenSilently])

  return (
    <div>
      <Navbar/>
    <div className="home">
      <div className="items">
        {items && items.map(item => (
          <ItemDetails item={item} key={item._id} />
        ))}
      </div>
      <ItemForm />
    </div>
    </div>
  )
}

export default Home