import React, {useState} from 'react'
import { useItemsContext } from '../hooks/useItemsContext'
import { useAuth0 } from "@auth0/auth0-react";



const ItemForm = () => {
    const { dispatch } = useItemsContext();
    const { getAccessTokenSilently } = useAuth0();

    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();// prevent the default form submission/page refresh

        const item = {name, quantity};
        const accessToken = await getAccessTokenSilently();
        const apiBaseUrl = process.env.REACT_APP_API_SERVER_URL;
        try {
            const response = await fetch(`${apiBaseUrl}/api/items`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(item)
            });
            const json = await response.json();

            if (!response.ok) { // error handling
                console.error('Failed to create item', json);
                setError(json.message);
            } else {
                console.log('Item created', json);
                setError(null);
                setName('');
                setQuantity(null);
                dispatch({type: 'CREATE_ITEM', payload: json})
            }
        } catch (error) {
            console.error('Failed to make API call', error);
            setError('Failed to make API call');
        }
      
    }

    return (
        <form className = "create" onSubmit = {handleSubmit}>          
            <input style={{width: '100px'}}
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
            />
            <label>qty</label>
            <input style={{width: '40px'}}
                type="number"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity || ''}
            />
            <button type="submit">Add Item</button>
        </form>

    );
}

export default ItemForm;