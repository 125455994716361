import { useAuth0 } from "@auth0/auth0-react";


export const Callback = () => {

    const { user, isLoading, isAuthenticated } = useAuth0;

    if (isLoading) {
        return <div>Loading ...</div>;
      }
    

    return (

        <div> ...Callback...  </div>  
    )

    

}