import { useItemsContext } from '../hooks/useItemsContext'
import { useAuth0 } from "@auth0/auth0-react";

const ItemDetails = ({ item }) => {

    const { dispatch } = useItemsContext()
    const { getAccessTokenSilently } = useAuth0();

    const onDelete = async () => {
        const accessToken = await getAccessTokenSilently();
        const apiBaseUrl = process.env.REACT_APP_API_SERVER_URL;
        try {
            const response = await fetch(`${apiBaseUrl}/api/items/${item._id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            //const json = await response.json();  // no longer returning anything, just a 204. 

            if (response.ok) {
                console.log('Item deleted');
                dispatch({ type: 'DELETE_ITEM', payload: item })
            } else {
                console.error('Failed to delete item');
            }
        } catch (error) {
            console.error('Failed to make API call', error);
        }
    }

    const handleDelete = (e) => {
        e.preventDefault();
        onDelete();
    }

    return (
        <div className="item-details">
            
            <h4>
            <button onClick={handleDelete}>X</button>
                {' '}
                {item.name}
                {item.quantity && ` (qty: ${item.quantity})`}
            </h4>
        </div>
    );
}



export default ItemDetails;  